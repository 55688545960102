export type Month = {
  text:
    | 'Jan'
    | 'Feb'
    | 'Mar'
    | 'Apr'
    | 'May'
    | 'Jun'
    | 'Jul'
    | 'Aug'
    | 'Sep'
    | 'Oct'
    | 'Nov'
    | 'Dec';
  value: number;
};
export type Year = {
  value: number;
  text: string;
};
export const updateMonth = (
  event: InputEvent | MouseEvent,
  updateMonthYear: UpdateMonthYear,
  year: number,
) => {
  updateMonthYear(+(event.target as HTMLSelectElement).value, year);
};
export const nextMonth = (month: number, year: number, updateMonthYear: UpdateMonthYear) => {
  month++;
  if (month > 11) {
    month = 0;
    year++;
  }
  updateMonthYear(month, year);
};
export const previousMonth = (month: number, year: number, updateMonthYear: UpdateMonthYear) => {
  month--;
  if (month < 0) {
    month = 11;
    year--;
  }
  updateMonthYear(month, year);
};

// internal types
type UpdateMonthYear = (month: number, year: number) => void;
